import { isNonEmptyObject, isNullish, isValidValue } from '.';
import momentUtils from './date_utils/moment/index';

import { contractSectors } from './enums';
import { CONTRACT_NOT_DELETABLE } from './messages';
import { contractStatuses, contractStatusesTranslation, coupDePouceTypesTranslations, uploadedDocumentStatuses } from './enums';
import operationHelper from './operation-helper';
import dateUtils from './date_utils';
import operationSheetNames from '../utils/operation-sheet-names';
import companies from '../data/companies';

function formatContractsForTable(contracts) {
    return contracts.map((contract) => {
        const { startDate, endDate, delegateeName, reference, prices, sectors, status, uuid } = contract;
        return {
            startDate,
            endDate,
            delegateeName,
            reference,
            prices,
            sectors,
            status,
            contract,
            uuid,
        };
    });
}

function canShowOtherInformation(contract) {
    return contract.customerMinimumEnergySavingCertificatePremiumPercentage > 0 || isValidValue(contract.mandatoryDetails);
}

function isContractDeletable(contract) {
    return contract.status !== uploadedDocumentStatuses.CONFIRMED;
}

function getContractDeleteButtonMessage(contract) {
    return isContractDeletable(contract) ? '' : CONTRACT_NOT_DELETABLE;
}

function formatContractStatus(contract) {
    return contractStatusesTranslation[getContractStatus(contract)];
}

function getSector(sector) {
    return contractSectors[sector];
}

function getDelegateeLogoFileUrl({ delegateeName } = {}) {
    const logoFileUrlTemplate = process.env.REACT_APP_DELEGATEE_LOGO_FILE_URL_TEMPLATE;

    return logoFileUrlTemplate && delegateeName ? encodeURI(logoFileUrlTemplate.replace('{delegateeName}', delegateeName.trim())) : '';
}

function getContractStatus(contract) {
    if (contract.status !== contractStatuses.CONFIRMED) return contract.status;

    return isContractActive(contract.startDate, contract.endDate, contract.status) ? contractStatuses.CONFIRMED : contractStatuses.EXPIRED;
}

function isContractActive(startDate, endDate, status) {
    return momentUtils.isSameOrBetween(momentUtils.getTodayAsDate(), startDate, endDate) && status === contractStatuses.CONFIRMED;
}

function formatCoupDePouceOperation({ operationSheetName, operationName }) {
    return `${operationSheetName.split('V')[0].replace(/_/g, '-')} : ${operationName}`;
}

function translateCoupDePouceType(type) {
    return 'Coup de Pouce '.concat(coupDePouceTypesTranslations[type] || '');
}

function formatCoupDePouceOperations(coupDePouceOperations = []) {
    const result = {};
    coupDePouceOperations.forEach((operation) => {
        if (isNullish(result[operation.type])) result[operation.type] = [];
        result[operation.type].push(operation);
    });

    return result;
}

function extractEitherContractOrAmendmentPrices(contract) {
    const activeAmendment = extractActiveAmendment(contract);

    return isNonEmptyObject(activeAmendment) ? activeAmendment.prices : contract.prices;
}

function extractActiveAmendment({ contractAmendments = [] }) {
    return contractAmendments.find(({ startDate, endDate }) => dateUtils.isSameOrBetween(new Date(), startDate, endDate));
}

function formatContractPrice(prices, priceType) {
    const price = prices.find(({ type }) => type === priceType);

    return `${price.amount} €/MWh`;
}

const shouldCdpOperationShouldBeAStandardOne = (operationSheetName, coupDePouceOperations) => {
    return !coupDePouceOperations.map(({ operationSheetName }) => operationSheetName).includes(operationSheetName);
};

const getOperationSheetNamesWhoWillFallbackToAStandardOne = (operations, { coupDePouceOperations = [] }) => {
    return operations
        .filter((operation) => operationHelper.isCDPOperation(operation?.operationSheetName) && shouldCdpOperationShouldBeAStandardOne(operation.operationSheetName, coupDePouceOperations))
        .map((operation) => operation.formattedOperationSheetName);
};

const doesTheContractApplyInTheCaseItsASynercielOne = (quotation, contract) => {
    return (
        contract.isASynercielContract &&
        quotation.operations.some(({ operationSheetName }) =>
            [operationSheetNames.BAR_TH_143V_A25_1, operationSheetNames.BAR_TH_143V_A51_5, operationSheetNames.BAR_TH_112V_A35_2, operationSheetNames.BAR_TH_112V_A46_3].includes(
                operationSheetName.replace('_CDP', '')
            )
        )
    );
};

const willOperationSheetNameFallbackToAStandardOne = (operations, { coupDePouceOperations = [] }) => {
    return !!operations
        .filter((operation) => operationHelper.isCDPOperation(operation?.operationSheetName))
        .find((operation) => shouldCdpOperationShouldBeAStandardOne(operation.operationSheetName, coupDePouceOperations));
};

function removeDuplicateInCoupDePouceOperations(typeOperations) {
    const formattedTypeOperations = [];
    typeOperations.forEach((typeOperation) => {
        const foundOperation = formattedTypeOperations.filter((operation) => operation?.operationSheetName.split('V_')[0] === typeOperation?.operationSheetName.split('V_')[0]);

        if (foundOperation.length === 0) formattedTypeOperations.push(typeOperation);
    });

    return formattedTypeOperations;
}

function hasContractCorrespondingToTheObligated(obligated, contracts) {
    return contracts.some((contract) => contract.delegateeSiren === obligated.siren);
}

function getContractMandatoryInformation(delegateeSiren) {
    for (const companyName in companies) {
        const company = companies[companyName];
        if (company.siren === delegateeSiren) {
            return company.contractMandatoryInformation;
        }
    }
    return '';
}

export default {
    canShowOtherInformation,
    formatContractsForTable,
    formatContractStatus,
    getContractDeleteButtonMessage,
    isContractDeletable,
    getDelegateeLogoFileUrl,
    willOperationSheetNameFallbackToAStandardOne,
    getOperationSheetNamesWhoWillFallbackToAStandardOne,
    shouldCdpOperationShouldBeAStandardOne,
    getContractStatus,
    getSector,
    formatCoupDePouceOperation,
    translateCoupDePouceType,
    formatCoupDePouceOperations,
    extractEitherContractOrAmendmentPrices,
    formatContractPrice,
    removeDuplicateInCoupDePouceOperations,
    doesTheContractApplyInTheCaseItsASynercielOne,
    hasContractCorrespondingToTheObligated,
    getContractMandatoryInformation,
};
