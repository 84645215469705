import {
    LABEL_EPAISSEUR,
    LABEL_RESISTANCE,
    LABEL_EFFICACITE_ENERGETIQUE,
    LABEL_TYPE_FONCTIONNEMENT,
    LABEL_MARQUE_REGULATEUR,
    LABEL_CLASSE_REGULATEUR,
    LABEL_REFERENCE_REGULATEUR,
    LABEL_FACTEUR_SOLAIRE,
    LABEL_TYPE_DE_CONSTITUTION,
    LABEL_COEFFICIANT_DE_TRANSMISSION,
    LABEL_PUISSANCE_THERMIQUE,
    LABEL_MODELE_VENTILATION,
    LABEL_TYPE_VENTILATION,
    LABEL_CLASSE_ENERGETIQUE_VENTILATION,
    LABEL_REFERENCE_AVIS_TECHNIQUE,
    LABEL_DATE_VALIDITE_AVIS_TECHNIQUE,
    LABEL_PUISSANCE_ELECTRIQUE,
    LABEL_TYPE_CAISSON,
    LABEL_MARQUE_CAISSON,
    LABEL_REFERENCE_CAISSON,
    LABEL_MARQUE_BOUCHES_EXTRACTION,
    LABEL_REFERENCE_BOUCHES_EXTRACTION,
    LABEL_MARQUE_BOUCHE_AIR,
    LABEL_REFERENCE_BOUCHE_AIR,
    LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU,
    LABEL_COEFFICIANT_DE_PERFORMANCE,
    HYBRID_SOLAR_CAPTORS,
    LABEL_NATURE_FLUID_CAPTORS,
    LABEL_SEASONAL_PERFORMANCE_COEFFICIENT,
    LABEL_NOMINAL_POWER,
    SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER,
    LABEL_RACKING_PROFILE,
    LABEL_GREEN_FLAME_LABEL_SEVEN,
    LABEL_NOMINAL_YIELD,
    LABEL_PARTICLE_EMISSION,
    LABEL_CARBON_MONOXIDE_EMISSIONS,
    LABEL_NITROGEN_OXIDE_EMISSIONS,
    LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS,
    LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
    LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189,
    LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
    LABEL_SEASONAL_PARTICLE_EMISSIONS,
    LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS,
    LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS,
    LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE,
    LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE,
    LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE,
    LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY,
    LABEL_INPUT_EXCHANGER_THERMAL_EFFICIENCY,
    LABEL_SELECT_VENTILATION_ENERGY_CLASS_1254_2014,
    LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE,
    LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE,
    LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_BRAND,
    LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_REFERENCE,
    LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_BRAND,
    LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_REFERENCE,
    LABEL_COEFFICIENT_OF_PERFORMANCE_7_45,
    LABEL_COEFFICIENT_OF_PERFORMANCE_ECS,
    LABEL_NOMINAL_POWER_WITH_TEMP,
    LABEL_COEFFICIENT_OF_PERFORMANCE,
    LABEL_SELECT_BACKUP_ENERGY,
    LABEL_SELECT_RACKING_PROFILE,
    LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_ONE,
    LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_TWO,
    LABEL_COEFFICIENT_OF_PERFORMANCE_7_55,
    LABEL_COEFFICIENT_OF_PERFORMANCE_7_35,
    LABEL_ULOR,
    LABEL_ULR,
    LABEL_LIGHT_EFFICIENCY,
    LABEL_LUMINARY_TYPE,
    LABEL_LIGHT_IS_LED,
    LABEL_OPTICAL_ASSEMBLY,
    LABEL_LUMEN,
    LABEL_POWER_OF_THE_SYSTEM_W,
    LABEL_INDEX_COLOR_RENDERING,
    LABEL_LUMINARY_MODELE,
    LABEL_REPLACE_LIGHT_SOURCE,
    NAME_PANEL_POWER,
    NAME_PANEL_CELLS,
    NAME_PANEL_DIMENSION,
    NAME_PANEL_WEIGHT,
    NAME_PANEL_CERTIFICATIONS,
    NAME_MANUFACTURER_WARRANTY,
    NAME_LINEAR_WARRANTY_85PCT,
    NAME_MAX_DC_VOLTAGE,
    NAME_YIELD,
    NAME_MATERIAL_WARRANTY,
    NAME_MODEL,
    LABEL_PANEL_POWER,
    LABEL_PANEL_CELLS,
    LABEL_PANEL_DIMENSION,
    LABEL_PANEL_WEIGHT,
    LABEL_PANEL_CERTIFICATIONS,
    LABEL_MANUFACTURER_WARRANTY,
    LABEL_LINEAR_WARRANTY_85PCT,
    LABEL_MAX_DC_VOLTAGE,
    LABEL_YIELD,
    LABEL_MATERIAL_WARRANTY,
    LABEL_MODEL,
    LABEL_HEATING_PUMP_USAGE,
    LABEL_REGULARTOR_CLASS,
    LABEL_MAXIMUM_SERVICE_TEMPERATURE,
    LABEL_FIFTY_DEGREES_THERMAL_RESISTANCE,
    LABEL_ONE_HUNDRED_DEGREES_THERMAL_RESISTANCE,
    LABEL_INSULATING_COVER_IS_FLEXIBLE,
    LABEL_INSULATING_COVER_IS_MADE_OF_MINERAL_WOOL_BASED_INSULATOR,
    LABEL_INSULATING_COVER_IS_NOT_AN_INSULATING_SLEEVE,
    LABEL_HEAT_TRANSFER_FLUID_TEMPERATURE,
    LABEL_CAPTOR_BRAND,
    LABEL_CAPTOR_REFERENCE,
} from './product-constants';
import {
    productTypes,
    productCategoriesValues,
    productSubCategories,
    productFileTypes,
    functioningAlimentationTypes,
    operationInstallationTypes,
    energyRenovationWorks,
    photovoltaicPanelsSubCategoriesTranslation,
} from './enums';
import { isNullishOrEmpty, isValidValue, removeNullishProperties } from '.';
import {
    LABEL_INPUT_SOLAR_CAPTORS_PRODUCTIVITY,
    LABEL_INPUT_TANK_CAPACITY_IN_LITER,
    LABEL_INPUT_CERTIFICATION_NUMBER_SOLARKEYMARK,
    LABEL_INPUT_TANK_CLASS,
    LABEL_PAC_DOUBLE_SERVICE_WITH_ECS,
    LABEL_INPUT_SHOWER_HEAD_CLASS,
    LABEL_INPUT_JET_REGULATOR_CLASS,
    THERMAL_COEFFICIENT_UD,
} from './form_labels';
import {
    LABEL_REGULATION_SYSTEM_CENTRAL_SYSTEM_INCLUDES_API,
    LABEL_REGULATION_SYSTEM_CLASS,
    LABEL_REGULATION_SYSTEM_EQUIPMENT_CAN_HELP_DEROGATE_FROM_INSTRUCTIONS,
    LABEL_REGULATION_SYSTEM_INCLUDES_CENTRAL_SYSTEM,
    LABEL_REGULATION_SYSTEM_INCLUDES_PROBE,
    LABEL_REGULATION_SYSTEM_INCLUDES_REGULATOR,
    LABEL_REGULATION_SYSTEM_RUNS_OFFLINE,
    LABEL_REGULATION_SYSTEM_SETTINGS_ARE_LOCALLY_UPDATABLE,
    LABEL_REGULATION_SYSTEM_SIGNAL_ORIGIN,
} from './operation-constants';

/*
 * Insulator
 */
function setEnegeryEfficiencyPropertiesForInsulator(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_RESISTANCE, value: data[LABEL_RESISTANCE] || 0 });
}

function setProductDetailsToSpecifyForInsulator(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_EPAISSEUR, value: data[LABEL_EPAISSEUR] });
}

/*
 * Heating pump
 */
function setEnegeryEfficiencyPropertiesForHeatingPump({ subCategory, ...data }, energyEfficiencyProperties) {
    if (subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR) {
        energyEfficiencyProperties.push({ label: LABEL_SEASONAL_PERFORMANCE_COEFFICIENT, value: data[LABEL_SEASONAL_PERFORMANCE_COEFFICIENT] });
        energyEfficiencyProperties.push({ label: LABEL_NOMINAL_POWER, value: data[LABEL_NOMINAL_POWER] });
        if (data[LABEL_COEFFICIENT_OF_PERFORMANCE]) energyEfficiencyProperties.push({ label: LABEL_COEFFICIENT_OF_PERFORMANCE, value: data[LABEL_COEFFICIENT_OF_PERFORMANCE] });
    } else {
        energyEfficiencyProperties.push({ label: LABEL_EFFICACITE_ENERGETIQUE, value: data[LABEL_EFFICACITE_ENERGETIQUE] });
        if (
            [productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU, productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU, productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU].includes(
                subCategory
            )
        ) {
            if (data[LABEL_NOMINAL_POWER_WITH_TEMP])
                energyEfficiencyProperties.push({
                    label: LABEL_NOMINAL_POWER_WITH_TEMP,
                    value: data[LABEL_NOMINAL_POWER_WITH_TEMP],
                });
            if (data[LABEL_COEFFICIENT_OF_PERFORMANCE_7_45])
                energyEfficiencyProperties.push({
                    label: LABEL_COEFFICIENT_OF_PERFORMANCE_7_45,
                    value: data[LABEL_COEFFICIENT_OF_PERFORMANCE_7_45],
                });
            if (data[LABEL_COEFFICIENT_OF_PERFORMANCE_7_35]) energyEfficiencyProperties.push({ label: LABEL_COEFFICIENT_OF_PERFORMANCE_7_35, value: data[LABEL_COEFFICIENT_OF_PERFORMANCE_7_35] });
            if (data[LABEL_COEFFICIENT_OF_PERFORMANCE_7_55]) energyEfficiencyProperties.push({ label: LABEL_COEFFICIENT_OF_PERFORMANCE_7_55, value: data[LABEL_COEFFICIENT_OF_PERFORMANCE_7_55] });
        }
        if (data[LABEL_PAC_DOUBLE_SERVICE_WITH_ECS] && data[LABEL_COEFFICIENT_OF_PERFORMANCE_ECS]) {
            energyEfficiencyProperties.push({ label: LABEL_COEFFICIENT_OF_PERFORMANCE_ECS, value: data[LABEL_COEFFICIENT_OF_PERFORMANCE_ECS] });
        }
    }
}

function setProductDetailsToSpecifyForHeatingPump({ subCategory, ...data }, productDetailsToSpecify) {
    if (subCategory !== productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR) {
        pushSpecifiedDetailInProductDetailsToSpecify({ productDetailsToSpecify, data, label: LABEL_TYPE_FONCTIONNEMENT });
        if (
            [productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU, productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU, productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_SOL_EAU].includes(
                subCategory
            )
        ) {
            [LABEL_PAC_DOUBLE_SERVICE_WITH_ECS, LABEL_RACKING_PROFILE, LABEL_INPUT_TANK_CAPACITY_IN_LITER, LABEL_HEATING_PUMP_USAGE, LABEL_REGULARTOR_CLASS].forEach((label) => {
                pushSpecifiedDetailInProductDetailsToSpecify({ productDetailsToSpecify, data, label });
            });
        }
    }
    if (subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE) {
        [
            { label: LABEL_MARQUE_REGULATEUR },
            { label: LABEL_REFERENCE_REGULATEUR },
            { label: LABEL_CLASSE_REGULATEUR },
            { label: LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU, defaultValue: false },
        ].forEach(({ label, defaultValue }) => {
            pushSpecifiedDetailInProductDetailsToSpecify({ productDetailsToSpecify, data, label, defaultValue });
        });
    }
}

function pushSpecifiedDetailInProductDetailsToSpecify({ productDetailsToSpecify, data, label, defaultValue }) {
    const value = data[label] || defaultValue;
    if (isValidValue(value)) productDetailsToSpecify.push({ label, value });
}

/*
 * Roof
 */
function setEnegeryEfficiencyPropertiesForRoof(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_FACTEUR_SOLAIRE, value: data[LABEL_FACTEUR_SOLAIRE] });
}

function setProductDetailsToSpecifyForRoof(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_TYPE_DE_CONSTITUTION, value: data[LABEL_TYPE_DE_CONSTITUTION] });
}

/*
 * Window
 */
function setEnegeryEfficiencyPropertiesForWindow(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_FACTEUR_SOLAIRE, value: data[LABEL_FACTEUR_SOLAIRE] });
    energyEfficiencyProperties.push({ label: LABEL_COEFFICIANT_DE_TRANSMISSION, value: data[LABEL_COEFFICIANT_DE_TRANSMISSION] });
}

function setProductDetailsToSpecifyForWindow() {}

/*
 * Individual boiler
 */
function setEnegeryEfficiencyPropertiesForIndividualBoiler(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_EFFICACITE_ENERGETIQUE, value: data[LABEL_EFFICACITE_ENERGETIQUE] });
    energyEfficiencyProperties.push({ label: LABEL_PUISSANCE_THERMIQUE, value: data[LABEL_PUISSANCE_THERMIQUE] });
}

function setProductDetailsToSpecifyForIndividualBoiler(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_MARQUE_REGULATEUR, value: data[LABEL_MARQUE_REGULATEUR] });
    productDetailsToSpecify.push({ label: LABEL_REFERENCE_REGULATEUR, value: data[LABEL_REFERENCE_REGULATEUR] });
    productDetailsToSpecify.push({ label: LABEL_CLASSE_REGULATEUR, value: data[LABEL_CLASSE_REGULATEUR] });
}

/*
 * Ventilation
 */
function setEnegeryEfficiencyPropertiesForVentilation(data, energyEfficiencyProperties) {
    if (data.subCategory === productSubCategories.VENTILATION.SIMPLE_FLOW_VENTILATION) {
        energyEfficiencyProperties.push({ label: LABEL_PUISSANCE_ELECTRIQUE, value: data[LABEL_PUISSANCE_ELECTRIQUE] });
    } else {
        if (data.installationType === operationInstallationTypes.COLLECTIVE_INSTALLATION_SELF_ADJUSTING) {
            energyEfficiencyProperties.push({ label: LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY, value: data[LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY] });
        } else {
            energyEfficiencyProperties.push({ label: LABEL_INPUT_EXCHANGER_THERMAL_EFFICIENCY, value: data[LABEL_INPUT_EXCHANGER_THERMAL_EFFICIENCY] });
            energyEfficiencyProperties.push({ label: LABEL_PUISSANCE_ELECTRIQUE, value: data[LABEL_PUISSANCE_ELECTRIQUE] });
        }
    }
}

function setProductDetailsToSpecifyForVentilation(data, productDetailsToSpecify) {
    if (data.subCategory === productSubCategories.VENTILATION.SIMPLE_FLOW_VENTILATION) {
        productDetailsToSpecify.push({ label: LABEL_TYPE_VENTILATION, value: data[LABEL_TYPE_VENTILATION] });
        productDetailsToSpecify.push({ label: LABEL_CLASSE_ENERGETIQUE_VENTILATION, value: data[LABEL_CLASSE_ENERGETIQUE_VENTILATION] });
        productDetailsToSpecify.push({ label: LABEL_REFERENCE_AVIS_TECHNIQUE, value: data[LABEL_REFERENCE_AVIS_TECHNIQUE] });
        productDetailsToSpecify.push({ label: LABEL_DATE_VALIDITE_AVIS_TECHNIQUE, value: data[LABEL_DATE_VALIDITE_AVIS_TECHNIQUE] });
        productDetailsToSpecify.push({ label: LABEL_TYPE_CAISSON, value: data[LABEL_TYPE_CAISSON] });
        productDetailsToSpecify.push({ label: LABEL_MARQUE_CAISSON, value: data[LABEL_MARQUE_CAISSON] });
        productDetailsToSpecify.push({ label: LABEL_REFERENCE_CAISSON, value: data[LABEL_REFERENCE_CAISSON] });
        productDetailsToSpecify.push({ label: LABEL_MARQUE_BOUCHES_EXTRACTION, value: data[LABEL_MARQUE_BOUCHES_EXTRACTION] });
        productDetailsToSpecify.push({ label: LABEL_REFERENCE_BOUCHES_EXTRACTION, value: data[LABEL_REFERENCE_BOUCHES_EXTRACTION] });

        if (data[LABEL_MARQUE_BOUCHE_AIR] && data[LABEL_REFERENCE_BOUCHE_AIR]) {
            productDetailsToSpecify.push({ label: LABEL_MARQUE_BOUCHE_AIR, value: data[LABEL_MARQUE_BOUCHE_AIR] });
            productDetailsToSpecify.push({ label: LABEL_REFERENCE_BOUCHE_AIR, value: data[LABEL_REFERENCE_BOUCHE_AIR] });
        }
    } else {
        if (data.installationType === operationInstallationTypes.COLLECTIVE_INSTALLATION_SELF_ADJUSTING) {
            productDetailsToSpecify.push({ label: LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE, value: data[LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE] });
            productDetailsToSpecify.push({ label: LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE, value: data[LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE] });
        } else {
            productDetailsToSpecify.push({ label: LABEL_SELECT_VENTILATION_ENERGY_CLASS_1254_2014, value: data[LABEL_SELECT_VENTILATION_ENERGY_CLASS_1254_2014] });
            if (data.installationType === operationInstallationTypes.INDIVIDUAL_INSTALLATION_MODULATED) {
                productDetailsToSpecify.push({ label: LABEL_REFERENCE_AVIS_TECHNIQUE, value: data[LABEL_REFERENCE_AVIS_TECHNIQUE] });
                productDetailsToSpecify.push({ label: LABEL_DATE_VALIDITE_AVIS_TECHNIQUE, value: data[LABEL_DATE_VALIDITE_AVIS_TECHNIQUE] });
            }
        }
        productDetailsToSpecify.push({ label: LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_BRAND, value: data[LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_BRAND] });
        productDetailsToSpecify.push({ label: LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_REFERENCE, value: data[LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_REFERENCE] });
        productDetailsToSpecify.push({ label: LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_BRAND, value: data[LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_BRAND] });
        productDetailsToSpecify.push({ label: LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_REFERENCE, value: data[LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_REFERENCE] });
    }
}

function setProductDetailsForSolarWaterHeater(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_NATURE_FLUID_CAPTORS, value: data[LABEL_NATURE_FLUID_CAPTORS] });
    productDetailsToSpecify.push({ label: LABEL_INPUT_TANK_CAPACITY_IN_LITER, value: data[LABEL_INPUT_TANK_CAPACITY_IN_LITER] });
    productDetailsToSpecify.push({ label: LABEL_INPUT_CERTIFICATION_NUMBER_SOLARKEYMARK, value: data[LABEL_INPUT_CERTIFICATION_NUMBER_SOLARKEYMARK] });
    productDetailsToSpecify.push({ label: LABEL_INPUT_TANK_CLASS, value: data[LABEL_INPUT_TANK_CLASS] });
}
function setProductExclusionDetailsForSolarWaterHeater(data, productExclusionDetailsToSpecify) {
    productExclusionDetailsToSpecify.push({ label: HYBRID_SOLAR_CAPTORS, value: data[HYBRID_SOLAR_CAPTORS] });
}

function setProductExclusionDetailsForCombineSolarSystem(data, productExclusionDetailsToSpecify) {
    productExclusionDetailsToSpecify.push({ label: HYBRID_SOLAR_CAPTORS, value: data[HYBRID_SOLAR_CAPTORS] });
}

function setProductDetailsForCombineSolarSystem(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_NATURE_FLUID_CAPTORS, value: data[LABEL_NATURE_FLUID_CAPTORS] });
    productDetailsToSpecify.push({ label: SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER, value: data[SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER] });
    productDetailsToSpecify.push({ label: LABEL_INPUT_TANK_CAPACITY_IN_LITER, value: data[LABEL_INPUT_TANK_CAPACITY_IN_LITER] });
    productDetailsToSpecify.push({ label: LABEL_INPUT_CERTIFICATION_NUMBER_SOLARKEYMARK, value: data[LABEL_INPUT_CERTIFICATION_NUMBER_SOLARKEYMARK] });
    productDetailsToSpecify.push({ label: LABEL_CAPTOR_BRAND, value: data[LABEL_CAPTOR_BRAND] });
    productDetailsToSpecify.push({ label: LABEL_CAPTOR_REFERENCE, value: data[LABEL_CAPTOR_REFERENCE] });
    productDetailsToSpecify.push({ label: LABEL_INPUT_TANK_CLASS, value: data[LABEL_INPUT_TANK_CLASS] });
}

/*
 * Thermodynamic water heater
 */
function setEnegeryEfficiencyPropertiesForThermodynamicWaterHeater(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_COEFFICIANT_DE_PERFORMANCE, value: data[LABEL_COEFFICIANT_DE_PERFORMANCE] });
}

function setEnegeryEfficiencyPropertiesForSolarWaterHeater(data, energyEfficiencyProperties) {
    return energyEfficiencyProperties;
}

function setProductDetailsForThermodynamicWaterHeater(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_RACKING_PROFILE, value: data[LABEL_RACKING_PROFILE] });
    if (data[LABEL_INPUT_TANK_CAPACITY_IN_LITER]) productDetailsToSpecify.push({ label: LABEL_INPUT_TANK_CAPACITY_IN_LITER, value: data[LABEL_INPUT_TANK_CAPACITY_IN_LITER] });
}

/*
 * Independent wood stove
 */
function setProductDetailsForIndependentWoodStove(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_GREEN_FLAME_LABEL_SEVEN, value: data[LABEL_GREEN_FLAME_LABEL_SEVEN] });
    productDetailsToSpecify.push({ label: LABEL_TYPE_FONCTIONNEMENT, value: data[LABEL_TYPE_FONCTIONNEMENT] });
}

function setProductDetailsForIndividualBiomassBoiler(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_TYPE_FONCTIONNEMENT, value: data[LABEL_TYPE_FONCTIONNEMENT] });
    productDetailsToSpecify.push({ label: LABEL_GREEN_FLAME_LABEL_SEVEN, value: data[LABEL_GREEN_FLAME_LABEL_SEVEN] });
    productDetailsToSpecify.push({ label: LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE, value: data[LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE] });

    const functionalType = data[LABEL_TYPE_FONCTIONNEMENT];
    const label = functionalType === functioningAlimentationTypes.AUTOMATIC_ALIMENTATION_PELLET_BOILER ? LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE : LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE;
    productDetailsToSpecify.push({ label, value: data[label] });
    productDetailsToSpecify.push({ label: LABEL_CLASSE_REGULATEUR, value: data[LABEL_CLASSE_REGULATEUR] });
}

function setEnegeryEfficiencyPropertiesForIndependentWoodStove(data, energyEfficiencyProperties) {
    if (data[LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS]) energyEfficiencyProperties.push({ label: LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS, value: data[LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS] });
    if (isValidValue(data[LABEL_GREEN_FLAME_LABEL_SEVEN]) && !data[LABEL_GREEN_FLAME_LABEL_SEVEN]) {
        if (data[LABEL_NOMINAL_YIELD]) energyEfficiencyProperties.push({ label: LABEL_NOMINAL_YIELD, value: data[LABEL_NOMINAL_YIELD] });
        if (data[LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS])
            energyEfficiencyProperties.push({
                label: LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
                value: data[LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS],
            });

        if (data[LABEL_PARTICLE_EMISSION])
            energyEfficiencyProperties.push({
                label: LABEL_PARTICLE_EMISSION,
                value: data[LABEL_PARTICLE_EMISSION],
            });

        if (data[LABEL_CARBON_MONOXIDE_EMISSIONS])
            energyEfficiencyProperties.push({
                label: LABEL_CARBON_MONOXIDE_EMISSIONS,
                value: data[LABEL_CARBON_MONOXIDE_EMISSIONS],
            });

        if (data[LABEL_NITROGEN_OXIDE_EMISSIONS])
            energyEfficiencyProperties.push({
                label: LABEL_NITROGEN_OXIDE_EMISSIONS,
                value: data[LABEL_NITROGEN_OXIDE_EMISSIONS],
            });
    }
}

function setEnegeryEfficiencyPropertiesForIndividualBiomassBoiler(data, energyEfficiencyProperties) {
    if (data[LABEL_PUISSANCE_THERMIQUE]) energyEfficiencyProperties.push({ label: LABEL_PUISSANCE_THERMIQUE, value: data[LABEL_PUISSANCE_THERMIQUE] });
    if (data[LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189])
        energyEfficiencyProperties.push({ label: LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189, value: data[LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189] });
    if (isValidValue(data[LABEL_GREEN_FLAME_LABEL_SEVEN]) && !data[LABEL_GREEN_FLAME_LABEL_SEVEN]) {
        if (data[LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS]) energyEfficiencyProperties.push({ label: LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS, value: data[LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS] });
        if (data[LABEL_SEASONAL_PARTICLE_EMISSIONS]) energyEfficiencyProperties.push({ label: LABEL_SEASONAL_PARTICLE_EMISSIONS, value: data[LABEL_SEASONAL_PARTICLE_EMISSIONS] });
        if (data[LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS]) energyEfficiencyProperties.push({ label: LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS, value: data[LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS] });
        if (data[LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS])
            energyEfficiencyProperties.push({ label: LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS, value: data[LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS] });
    }
}

function setEnegeryEfficiencyPropertiesForPublicLighting(data, energyEfficiencyProperties) {
    if (data[LABEL_ULOR]) energyEfficiencyProperties.push({ label: LABEL_ULOR, value: data[LABEL_ULOR] });
    if (data[LABEL_ULR]) energyEfficiencyProperties.push({ label: LABEL_ULR, value: data[LABEL_ULR] });
    if (data[LABEL_LIGHT_EFFICIENCY]) energyEfficiencyProperties.push({ label: LABEL_LIGHT_EFFICIENCY, value: data[LABEL_LIGHT_EFFICIENCY] });
    if (data[LABEL_OPTICAL_ASSEMBLY]) energyEfficiencyProperties.push({ label: LABEL_OPTICAL_ASSEMBLY, value: data[LABEL_OPTICAL_ASSEMBLY] });
}
function setEnergyEfficiencyPropertiesForCombineSolarSystem(data, energyEfficiencyProperties) {
    energyEfficiencyProperties.push({ label: LABEL_INPUT_SOLAR_CAPTORS_PRODUCTIVITY, value: data[LABEL_INPUT_SOLAR_CAPTORS_PRODUCTIVITY] });
}

/*
 * Solar water heater domtom
 */
function setProductDetailsForSolarWaterHeaterDomtom(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_SELECT_BACKUP_ENERGY, value: data[LABEL_SELECT_BACKUP_ENERGY] });
    productDetailsToSpecify.push({ label: LABEL_SELECT_RACKING_PROFILE, value: data[LABEL_SELECT_RACKING_PROFILE] });
    productDetailsToSpecify.push({ label: LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_ONE, value: data[LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_ONE] });
    productDetailsToSpecify.push({ label: LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_TWO, value: data[LABEL_CHECKBOX_DOMTOM_WATER_HEATER_MANDATORY_AGREEMENT_TWO] });
}

function setProductDetailsForPublicLighting(data, productDetailsToSpecify) {
    productDetailsToSpecify.push({ label: LABEL_LUMINARY_MODELE, value: data[LABEL_LUMINARY_MODELE] });
    productDetailsToSpecify.push({ label: LABEL_LUMINARY_TYPE, value: data[LABEL_LUMINARY_TYPE] });
    productDetailsToSpecify.push({ label: LABEL_LIGHT_IS_LED, value: data[LABEL_LIGHT_IS_LED] });
    productDetailsToSpecify.push({ label: LABEL_LUMEN, value: data[LABEL_LUMEN] });
    productDetailsToSpecify.push({ label: LABEL_POWER_OF_THE_SYSTEM_W, value: data[LABEL_POWER_OF_THE_SYSTEM_W] });
    productDetailsToSpecify.push({ label: LABEL_REPLACE_LIGHT_SOURCE, value: data[LABEL_REPLACE_LIGHT_SOURCE] });
    if (data[LABEL_INDEX_COLOR_RENDERING]) productDetailsToSpecify.push({ label: LABEL_INDEX_COLOR_RENDERING, value: data[LABEL_INDEX_COLOR_RENDERING] });
}

function setProductDetailsForWaterSavingSystems(data, productDetailsToSpecify) {
    if (data[LABEL_INPUT_SHOWER_HEAD_CLASS]) productDetailsToSpecify.push({ label: LABEL_INPUT_SHOWER_HEAD_CLASS, value: data[LABEL_INPUT_SHOWER_HEAD_CLASS] });
    if (data[LABEL_INPUT_JET_REGULATOR_CLASS]) productDetailsToSpecify.push({ label: LABEL_INPUT_JET_REGULATOR_CLASS, value: data[LABEL_INPUT_JET_REGULATOR_CLASS] });
}

function setEnergyRenovationWorkDetailsForPhotovoltaicPanels(data, energyRenovationWorkDetails) {
    if (isValidValue(data[NAME_PANEL_POWER])) energyRenovationWorkDetails.push({ label: LABEL_PANEL_POWER, value: data[NAME_PANEL_POWER] });
    if (isValidValue(data[NAME_PANEL_CELLS])) energyRenovationWorkDetails.push({ label: LABEL_PANEL_CELLS, value: data[NAME_PANEL_CELLS] });
    if (isValidValue(data[NAME_PANEL_DIMENSION])) energyRenovationWorkDetails.push({ label: LABEL_PANEL_DIMENSION, value: data[NAME_PANEL_DIMENSION] });
    if (isValidValue(data[NAME_PANEL_WEIGHT])) energyRenovationWorkDetails.push({ label: LABEL_PANEL_WEIGHT, value: data[NAME_PANEL_WEIGHT] });
    if (isValidValue(data[NAME_PANEL_CERTIFICATIONS])) energyRenovationWorkDetails.push({ label: LABEL_PANEL_CERTIFICATIONS, value: data[NAME_PANEL_CERTIFICATIONS] });
    if (isValidValue(data[NAME_MANUFACTURER_WARRANTY])) energyRenovationWorkDetails.push({ label: LABEL_MANUFACTURER_WARRANTY, value: data[NAME_MANUFACTURER_WARRANTY] });
    if (isValidValue(data[NAME_LINEAR_WARRANTY_85PCT])) energyRenovationWorkDetails.push({ label: LABEL_LINEAR_WARRANTY_85PCT, value: data[NAME_LINEAR_WARRANTY_85PCT] });
    if (isValidValue(data[NAME_MAX_DC_VOLTAGE])) energyRenovationWorkDetails.push({ label: LABEL_MAX_DC_VOLTAGE, value: data[NAME_MAX_DC_VOLTAGE] });
    if (isValidValue(data[NAME_YIELD])) energyRenovationWorkDetails.push({ label: LABEL_YIELD, value: data[NAME_YIELD] });
    if (isValidValue(data[NAME_MATERIAL_WARRANTY])) energyRenovationWorkDetails.push({ label: LABEL_MATERIAL_WARRANTY, value: data[NAME_MATERIAL_WARRANTY] });
    if (isValidValue(data[NAME_MODEL])) energyRenovationWorkDetails.push({ label: LABEL_MODEL, value: data[NAME_MODEL] });
}

function setProductDetailsForThermostaticValve(data, energyRenovationWorkDetails) {
    if (isValidValue(data[LABEL_MANUFACTURER_WARRANTY])) energyRenovationWorkDetails.push({ label: LABEL_MANUFACTURER_WARRANTY, value: data[LABEL_MANUFACTURER_WARRANTY] });
}

/**
 * Insulating cover
 */
function setEnegeryEfficiencyPropertiesForInsulatingCover(data, energyEfficiencyProperties) {
    [LABEL_MAXIMUM_SERVICE_TEMPERATURE, LABEL_FIFTY_DEGREES_THERMAL_RESISTANCE, LABEL_ONE_HUNDRED_DEGREES_THERMAL_RESISTANCE].forEach((label) => {
        if (data[label]) energyEfficiencyProperties.push({ label, value: data[label] });
    });
}

function setProductDetailsForInsulatingCover(data, productDetailsToSpecify) {
    [
        LABEL_INSULATING_COVER_IS_FLEXIBLE,
        LABEL_INSULATING_COVER_IS_MADE_OF_MINERAL_WOOL_BASED_INSULATOR,
        LABEL_INSULATING_COVER_IS_NOT_AN_INSULATING_SLEEVE,
        LABEL_HEAT_TRANSFER_FLUID_TEMPERATURE,
    ].forEach((label) => {
        if (data[label]) productDetailsToSpecify.push({ label, value: data[label] });
    });
}

function setEnegeryEfficiencyPropertiesForDoor(data, productDetailsToSpecify) {
    if (data[THERMAL_COEFFICIENT_UD]) productDetailsToSpecify.push({ label: THERMAL_COEFFICIENT_UD, value: data[THERMAL_COEFFICIENT_UD] });
}

/**
 * Regulation system
 */
function setProductDetailsForRegulationSystem(data, productDetailsToSpecify) {
    [
        LABEL_REGULATION_SYSTEM_CLASS,
        LABEL_REGULATION_SYSTEM_SIGNAL_ORIGIN,
        LABEL_REGULATION_SYSTEM_INCLUDES_CENTRAL_SYSTEM,
        LABEL_REGULATION_SYSTEM_INCLUDES_PROBE,
        LABEL_REGULATION_SYSTEM_INCLUDES_REGULATOR,
        LABEL_REGULATION_SYSTEM_SETTINGS_ARE_LOCALLY_UPDATABLE,
        LABEL_REGULATION_SYSTEM_EQUIPMENT_CAN_HELP_DEROGATE_FROM_INSTRUCTIONS,
        LABEL_REGULATION_SYSTEM_RUNS_OFFLINE,
        LABEL_REGULATION_SYSTEM_CENTRAL_SYSTEM_INCLUDES_API,
    ].forEach((label) => {
        if (data[label]) productDetailsToSpecify.push({ label, value: data[label] });
    });
}

function parseCategoryData(category, operationPrices, categoryData) {
    const energyEfficiencyProperties = [];
    const detailsPerOperation = {};
    const productDetailsToSpecify = [];
    const productExclusionDetailsToSpecify = [];
    const energyRenovationWorkDetails = [];
    const setEnergyEfficiencyPropertiesStrategies = {
        [productCategoriesValues.ISOLANT]: setEnegeryEfficiencyPropertiesForInsulator,
        [productCategoriesValues.POMPE_CHALEUR]: setEnegeryEfficiencyPropertiesForHeatingPump,
        [productCategoriesValues.TOITURE]: setEnegeryEfficiencyPropertiesForRoof,
        [productCategoriesValues.FENETRE]: setEnegeryEfficiencyPropertiesForWindow,
        [productCategoriesValues.CHAUDIERE_INDIVIDUELLE]: setEnegeryEfficiencyPropertiesForIndividualBoiler,
        [productCategoriesValues.VENTILATION]: setEnegeryEfficiencyPropertiesForVentilation,
        [productCategoriesValues.CHAUFFE_EAU_THERMODYNAMIQUE]: setEnegeryEfficiencyPropertiesForThermodynamicWaterHeater,
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL]: setEnegeryEfficiencyPropertiesForSolarWaterHeater,
        [productCategoriesValues.SYSTEME_SOLAIRE_COMBINE]: setEnergyEfficiencyPropertiesForCombineSolarSystem,
        [productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS]: setEnegeryEfficiencyPropertiesForIndependentWoodStove,
        [productCategoriesValues.CHAUDIERE_BIOMASSE_INDIVIDUELLE]: setEnegeryEfficiencyPropertiesForIndividualBiomassBoiler,
        [productCategoriesValues.ECLAIRAGE_PUBLIC]: setEnegeryEfficiencyPropertiesForPublicLighting,
        [productCategoriesValues.HOUSSE_ISOLANTE]: setEnegeryEfficiencyPropertiesForInsulatingCover,
        [productCategoriesValues.PORTE]: setEnegeryEfficiencyPropertiesForDoor,
    };
    const setEnergyEfficiencyPropertiesStrategy = setEnergyEfficiencyPropertiesStrategies[category];
    if (setEnergyEfficiencyPropertiesStrategy) setEnergyEfficiencyPropertiesStrategy(categoryData, energyEfficiencyProperties);

    const setProductDetailsToSpecifyStrategies = {
        [productCategoriesValues.ISOLANT]: setProductDetailsToSpecifyForInsulator,
        [productCategoriesValues.POMPE_CHALEUR]: setProductDetailsToSpecifyForHeatingPump,
        [productCategoriesValues.TOITURE]: setProductDetailsToSpecifyForRoof,
        [productCategoriesValues.FENETRE]: setProductDetailsToSpecifyForWindow,
        [productCategoriesValues.CHAUDIERE_INDIVIDUELLE]: setProductDetailsToSpecifyForIndividualBoiler,
        [productCategoriesValues.VENTILATION]: setProductDetailsToSpecifyForVentilation,
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL]: setProductDetailsForSolarWaterHeater,
        [productCategoriesValues.CHAUFFE_EAU_THERMODYNAMIQUE]: setProductDetailsForThermodynamicWaterHeater,
        [productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS]: setProductDetailsForIndependentWoodStove,
        [productCategoriesValues.SYSTEME_SOLAIRE_COMBINE]: setProductDetailsForCombineSolarSystem,
        [productCategoriesValues.CHAUDIERE_BIOMASSE_INDIVIDUELLE]: setProductDetailsForIndividualBiomassBoiler,
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE]: setProductDetailsForSolarWaterHeaterDomtom,
        [productCategoriesValues.ECLAIRAGE_PUBLIC]: setProductDetailsForPublicLighting,
        [productCategoriesValues.THERMOSTATIC_VALVE]: setProductDetailsForThermostaticValve,
        [productCategoriesValues.WATER_SAVING_SYSTEMS]: setProductDetailsForWaterSavingSystems,
        [productCategoriesValues.HOUSSE_ISOLANTE]: setProductDetailsForInsulatingCover,
        [productCategoriesValues.REGULATION_SYSTEM]: setProductDetailsForRegulationSystem,
    };

    const setProductExclusionDetailsToSpecifyStrategies = {
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL]: setProductExclusionDetailsForSolarWaterHeater,
        [productCategoriesValues.SYSTEME_SOLAIRE_COMBINE]: setProductExclusionDetailsForCombineSolarSystem,
    };

    const SetEnergyRenovationWorkDetailsStrategies = {
        [energyRenovationWorks.PHOTOVOLTAIC_PANELS]: setEnergyRenovationWorkDetailsForPhotovoltaicPanels,
    };

    const setProductDetailsToSpecifyStrategy = setProductDetailsToSpecifyStrategies[category];
    const setProductExclusionDetailstoSpecifyStrategy = setProductExclusionDetailsToSpecifyStrategies[category];
    const setEnergyRenovationWorkDetailsStrategy = SetEnergyRenovationWorkDetailsStrategies[category];

    if (setProductDetailsToSpecifyStrategy) setProductDetailsToSpecifyStrategy(categoryData, productDetailsToSpecify);
    if (setProductExclusionDetailstoSpecifyStrategy) setProductExclusionDetailstoSpecifyStrategy(categoryData, productExclusionDetailsToSpecify);
    operationPrices.forEach(({ operationSheetName, formattedOperationSheetName, defaultPrice, vatRate }) => {
        detailsPerOperation[operationSheetName] = {};
        detailsPerOperation[operationSheetName].defaultPrice = defaultPrice;
        detailsPerOperation[operationSheetName].details = {};
        detailsPerOperation[operationSheetName].details.productDetailsToSpecify = productDetailsToSpecify;
        detailsPerOperation[operationSheetName].details.productExclusionDetailsToSpecify = productExclusionDetailsToSpecify;
        detailsPerOperation[operationSheetName].vatRate = vatRate;
        detailsPerOperation[operationSheetName].formattedOperationSheetName = formattedOperationSheetName;

        if (category === productCategoriesValues.VENTILATION) {
            detailsPerOperation[operationSheetName].vmcTypeAorB = categoryData[LABEL_TYPE_VENTILATION];
        }

        if (setEnergyRenovationWorkDetailsStrategy) setEnergyRenovationWorkDetailsStrategy(categoryData, energyRenovationWorkDetails);

        if (category === energyRenovationWorks.PHOTOVOLTAIC_PANELS) {
            detailsPerOperation[operationSheetName].formattedOperationSheetName = photovoltaicPanelsSubCategoriesTranslation[categoryData.subCategory];
        }
    });

    return { energyEfficiencyProperties, detailsPerOperation, energyRenovationWorkDetails };
}

function getName(category, categoryData) {
    if (category !== productCategoriesValues.VENTILATION) return category;

    return categoryData[LABEL_MODELE_VENTILATION];
}

function parseProductFormData({
    category,
    subCategory,
    installationType,
    title,
    file,
    brand,
    reference,
    certification,
    description,
    operationPrices,
    installationDescription,
    productDetails,
    status,
    technicalSheetId = '',
    technicalSheetUrl = '',
    technicalSheetFile,
    certificationFileUrl = '',
    certificationFileId = '',
    certificationFileName = '',
    productFiles,
    ...categoryData
}) {
    const parsedCategoryData = parseCategoryData(category, operationPrices, { subCategory, installationType, ...categoryData });
    const parsedFormData = removeNullishProperties({
        category,
        subCategory,
        installationType,
        title,
        name: getName(category, categoryData),
        brand,
        file,
        reference,
        certification,
        description,
        installationDescription,
        productDetails,
        type: productTypes.MAIN_PRODUCT,
        fileType: getFileType(categoryData),
        options: getOptions(categoryData),
        status,
        technicalSheetId,
        technicalSheetUrl,
        technicalSheetFile,
        certificationFileUrl,
        certificationFileId,
        certificationFileName,
        productFiles,
        ...parsedCategoryData,
    });
    if (isNullishOrEmpty(productDetails)) delete parsedFormData.productDetails;

    return parsedFormData;
}

function getFileType(data) {
    if (data[LABEL_GREEN_FLAME_LABEL_SEVEN]) {
        return productFileTypes.GREEN_FLAME_LABEL_SEVEN;
    }
}

function getOptions(data) {
    if (isValidValue(data[LABEL_GREEN_FLAME_LABEL_SEVEN]) && !data[LABEL_GREEN_FLAME_LABEL_SEVEN] && data?.productFiles?.length > 0) {
        return {
            fileTypeToDelete: productFileTypes.GREEN_FLAME_LABEL_SEVEN,
        };
    }
}

export default {
    parseProductFormData,
};
